<template>
  <TTView>
    <VRow>
      <VCol>
        <ChoiceForm
          :entity="choice"
          :questions="questions"
          :loading="loading"
          @update:title="choice.title = $event"
          @update:right="choice.right = $event"
          @update:questionId="choice.questionId = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import ChoiceForm from '../../../components/multi-level-test/choices/ChoiceForm.vue';

export default {
  name: 'QuestionsChoicesEdit',

  components: {
    ChoiceForm,
  },

  data() {
    return {
      loading: false,
      questions: [],
      choice: {
        title: null,
        right: null,
        questionId: null,
      },
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },

    levelId() {
      return this.$route.params.levelId;
    },

    questionId() {
      return this.$route.params.questionId;
    },

    choiceId() {
      return this.$route.params.choiceId;
    },
  },

  async created() {
    await this.fetch();

    this.choice.questionId = this.questionId;
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { choiceId } = this;
        const data = { id: choiceId };

        const { questions } = await this.$di.api.MultiLevelTest.QuestionsIndex();
        const { choice } = await this.$di.api.MultiLevelTest.ChoicesGet({ data });

        this.questions = questions;
        this.choice = choice;
      } catch (err) {
        // no-error
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;

        const { choiceId, choice } = this;
        const data = { id: choiceId, choice };
        delete choice.id;

        await this.$di.api.MultiLevelTest.ChoicesUpdate({ data });

        this.$router.go(-1);
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
